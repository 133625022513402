<script setup lang="ts">
useHead({
	bodyAttrs: {
		class: 'bg-gray-100 dark:bg-gray-900',
	},
});
</script>

<template>
	<div>
		<slot />
	</div>
</template>

<style scoped></style>
